import React from 'react'

import Blurb from '../components/Blurb'
import Layout from '../components/layout'
import SEO from '../components/SEO';

const googleMapsLink = 'https://www.google.com/maps/dir//Deer+Mountain+Inn,+790+Co+Rd+25,+Tannersville,+NY+12485/@42.2205707,-74.1548921,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x89ddb1fde6fbab43:0xfb4f032e955afac0!2m2!1d-74.1197869!2d42.2205125!3e0';

const TravelPage = () => (
  <Layout>
    <SEO title="Travel" />
    <h2>By Car</h2>

    <Blurb>
      The Deer Mountain Inn is an easy two hours north of New York City — take the
      Thruway to Saugerties, then head about 20 minutes west. You can plan Google Maps
      directions <a href={googleMapsLink} target="_blank" rel="noopener noreferrer">here</a>.
    </Blurb>

    <h2>By Plane</h2>

    <Blurb>
      If you’re flying in, you might consider Albany International Airport, which
      is only an hour away and is served by American, Delta, JetBlue, Southwest, and United.
      Most major rental car companies are available there, too, with in-airport service. It’s
      a well-reviewed small airport, and your experience will be infinitely more pleasant there
      than if you fly into one of the big New York City airports. From ALB, you’ll take the
      Thruway south to Catskill/Cairo, and then head about a half-hour west. You can plan
      Google Maps directions <a href={googleMapsLink} target="_blank" rel="noopener noreferrer">here</a>.
    </Blurb>

    <Blurb>
      If you prefer to fly into one of the big New York City airports, we’d recommend Newark
      Liberty International, which will allow you to avoid driving through the city and its
      traffic.
    </Blurb>

    <h2>Local Transportation</h2>

    <Blurb>
      We’ll offer shuttle service from hotels on the night of the wedding,
      with details to come. Uber and Lyft work, but wait times can be long.
    </Blurb>

    <Blurb>
      There are also several area taxi companies, including Tannersville-based
      Smiley’s Taxi, <a href="tel:1-518-589-6533">518-589-6533</a>.
    </Blurb>
  </Layout>
)

export default TravelPage
